$(document).foundation({
    equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});

$.cookieBar(
    options =
    {
        message: 'By using this website you are agreeing to our cookie policy',
        acceptButton: false,
        policyButton: true,
        policyText: 'Cookie Policy',
        policyURL: '/privacy-policy/',
        autoEnable: true,
        acceptOnContinue: true,
        acceptOnScroll: true,
        expireDays: 365,
        renewOnVisit: false
    }
);


$(document).ready(function(){



    //  Homepage Grid Navigation Isotope
    var $grid = $('.block__grid--navigation').isotope({
        itemSelector: '.grid--item',
        layoutMode: 'masonry',
    });


    //  News Grid Navigation Isotope
    var $grid = $('.block__articles--grid').isotope({
        itemSelector: '.article--item',
        layoutMode: 'masonry',
    });


    // ****************************************
    //  Stores Grid Navigation Isotope
    // ****************

    var $storeGrid = $('.block__stores--list').isotope({
        itemSelector: '.store__item',
        layoutMode: 'fitRows',
        animateResizeContainer: false
    });

    // bind filter on select change
    $('.store--filter__button').on( 'click', function() {
        var $this = $(this);
        // get filter value from option value
        var filterValue = $this.data('filter');
        $storeGrid.isotope({ filter: filterValue });
    });

    // bind filter on select change
    $('.store--filter__button').on( 'change', function() {
        // get filter value from option value
        var filterValue = this.value;
        $storeGrid.isotope({ filter: filterValue });
    });

    // ****************
    //  End of Stores Grid Isotope
    // ****************************************

});



function m40_socialShare(url, winWidth, winHeight)
{
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open(url, 'm40_sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    return false;

}
