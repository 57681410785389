$(document).ready(function()
{
    $(".js-video-gallery").each(function()
    {
        var target = $(this);

        $(target).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: $(target).parents('.block').find('.js-video-controls'),
        });
    });


    $(".js-video-controls").each(function() {

        var target = $(this);

        $(target).slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: $(target).parents('.block').find('.js-video-gallery'),
            dots: false,
            focusOnSelect: true,
        });
    });

});