$(document).ready(function(){
    if (jQuery('.sort-table').length){
        jQuery('.sort-table').dataTable({
            "searching": false,
            "info": false,
            "paging": false
        });
    }
});

$.fn.dataTableExt.sErrMode = 'throw';