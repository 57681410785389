// $(document).ready(function()
// {
//
//     SC.initialize({
//         client_id: 'YOUR_CLIENT_ID'
//     });
//
//     var track_url = 'http://soundcloud.com/forss/flickermood';
//     SC.oEmbed(track_url, { auto_play: true }).then(function(oEmbed) {
//         console.log('oEmbed response: ', oEmbed);
//     });
//
// });