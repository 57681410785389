var MainMenu = (function() {

    var $listItems = $( '#primary-nav > ul > li' ),
        $menuItems = $listItems.children( 'a' ),
        $body = $( 'body' ),
        current = -1;

    function init() {
        $menuItems.on( 'click', open );
        $listItems.on( 'click', function( event ) { event.stopPropagation(); } );
    }

    function open( event ) {
        if( current !== -1 ) {
            $listItems.eq( current ).removeClass( 'open' );
        }

        var $item = $( event.currentTarget ).parent( 'li' ),
            idx = $item.index();

        console.log($item, $($item).children('div.sub').length);

        if($($item).children('div.sub').length == 0) return true;

        if($item.hasClass('logo')) return true;

        if( current === idx ) {
            $item.removeClass( 'open' );
            current = -1;
        }
        else {
            $item.addClass( 'open' );
            current = idx;
            $body.off( 'click' ).on( 'click', close );
        }

        return false;

    }

    function close( event ) {
        $listItems.eq( current ).removeClass( 'open' );
        current = -1;
    }

    return { init : init };

})();

$(function()
{
    MainMenu.init();
});

$(".menu-item-has-children").on('click', function() {
    $(this).toggleClass('open-subnav');
});


$(".menu-button").on('click', function()
{
    $("html").toggleClass('nav-is-open');
    $("nav.primary").toggleClass('open');
});


$(window).on('resize', function()
{
    $("html").removeClass('nav-is-open');
    $("nav.primary").removeClass('open');

    if(Foundation.utils.is_large_up())
    {
        $("nav.primary").show();
    }

    if(Foundation.utils.is_small_only() || Foundation.utils.is_medium_only())
    {
        $("nav.primary").removeClass('open');
    }
});